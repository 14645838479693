const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://2fxo9lnri6.execute-api.ap-southeast-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://tdtdri5hid.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://t29motuioi.execute-api.ap-southeast-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.staging.newcrest.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.newcrest.forwoodsafety.com',
    WEBSOCKET: 'wss://j12xi7i7gd.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
};

export default config;